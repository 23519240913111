import React from 'react';
import DefaultLayout from '../components/DefaultLayout';
import { Link } from 'gatsby';
import ContactForm from '../components/ContactForm';

interface Props {}

const ThankYou: React.FC = () => {
  return (
    <DefaultLayout>
      <div className='four-oh-four container max-w-lg mx-auto mt-10'>
        <ContactForm />
      </div>
    </DefaultLayout>
  );
};

export default ThankYou;
